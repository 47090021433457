import React, {useState, useEffect} from "react";

import CommonSection from "../components/UI/common-section/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import "../styles/cart-page.css";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { cartActions } from "../store/shopping-cart/cartSlice";
import { Link } from "react-router-dom";
import { getTableOrder } from "../assets/fake-data/products";

const Cart = () => {
  const stateRedux = useSelector((state) => state)
  const [order, setOrder] = useState({})
  useEffect(async () => {
    const res = await getTableOrder(stateRedux.restaurentInfo.tableId)
    console.log('hellllllooo',res);
    setOrder(res? res : {})
  }, [])
  
  const cartItems = useSelector((state) => state.cart.cartItems);
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  return (
    <Helmet title="Commandes">
      <CommonSection title="Vos commandes" />
      <section>
        <Container>
          <Row>
            <Col lg="12">
              {order.products?.length === 0 ? (
                <h5 className="text-center">Votre panier est vide</h5>
              ) : (
                <>
                  <h5 className="mb-5">Détails de la commande</h5>
                  <table className="table table-borderless mb-5 align-middle">
                    <tbody>
                      {order.products?.map((item) => (
                        <Tr item={item} key={item.id} />
                      ))}
                    </tbody>
                  </table>
                </>
              )}

              <div className="mt-4">
                <h6>
                  Total: 
                  <span className="cart__subtotal">{order.total?order.total:0}</span>
                  TND
                </h6>
                {/* <p>Paiement a la caisse</p> */}
                {/* <div className="cart__page-btn">
                  <button className="addTOCart__btn me-4">
                    <Link to="/pizzas">+ Ajouter</Link>
                  </button>
                  <button className="addTOCart__btn">
                    <Link to="/checkout">Passer à la caisse</Link>
                  </button>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

const Tr = (props) => {
  const { id, image01, name, price, order_products } = props.item;
  const dispatch = useDispatch();

  const deleteItem = () => {
    dispatch(cartActions.deleteItem(id));
  };
  return (
    <tr>
      {/* <td className="text-center cart__img-box">
        <img src={image01} alt="" />
      </td> */}
      <td className="text-center">{order_products.quantity}</td>
      <td className="text-center">{name}</td>
      <td className="text-center">{order_products.quantity*price}.TND</td>
      {/* <td className="text-center cart__item-del">
        <i className="ri-delete-bin-line" onClick={deleteItem}></i>
      </td> */}
    </tr>
  );
};

export default Cart;
