import { createSlice } from "@reduxjs/toolkit";

const restaurentInfo = createSlice({
  name: "restaurent",
  initialState: { tableId: null, restaurentId: null },

  reducers: {
    setRestaurentInfo(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const restaurentInfoActions = restaurentInfo.actions;
export default restaurentInfo;
