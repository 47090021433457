import React, {useState} from "react";

import { ListGroup } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import CartItem from "./CartItem";
import { useDispatch, useSelector } from "react-redux";
import { cartUiActions } from "../../../store/shopping-cart/cartUiSlice";

import "../../../styles/shopping-cart.css";
import { makeOrder } from "../../../assets/fake-data/products";
import { cartActions } from "../../../store/shopping-cart/cartSlice";

const Carts = () => {
  const dispatch = useDispatch();
  const cartProducts = useSelector((state) => state.cart.cartItems);
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  const stateRedux = useSelector((state) => state);
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate()
  const toggleCart =  () => {
      dispatch(cartUiActions.toggle());

  };
  const onClickPassOrder = async () => {
    setLoader(true)
    const res = await makeOrder(cartProducts, totalAmount, stateRedux.restaurentInfo.tableId)
    if (res === 200) {
      dispatch(cartActions.resetCart());
      dispatch(cartUiActions.toggle());
      navigate('/checkout')
    }

  };
  return (
    <div className="cart__container" onClick={toggleCart}>
      <ListGroup onClick={(event) => event.stopPropagation()} className="cart">
        <div className="cart__closeButton">
          <span onClick={toggleCart}>
            <i className="ri-close-fill"></i>
          </span>
        </div>

        <div className="cart__item-list">
          {cartProducts.length === 0 ? (
            <h6 className="text-center">Aucun article ajouté</h6>
          ) : (
            cartProducts.map((item, index) => (
              <CartItem item={item} key={index} onClose={toggleCart} />
            ))
          )}
        </div>

        <div className="cart__bottom d-flex align-items-center justify-content-between">
          <h6>
            Total : <span>{totalAmount} TND</span>
          </h6>
          <button onClick={!loader ? () => {onClickPassOrder()}: () => {console.log('not ready');}}>
            Passer la commande
          </button>
        </div>
      </ListGroup>
    </div>
  );
};

export default Carts;
