import React, { useEffect, useRef, useState } from "react";

import "../../../styles/product-card.css";

// import productImg from "../../../assets/images/product_2.1.jpg";

import { useDispatch } from "react-redux";
import { cartActions } from "../../../store/shopping-cart/cartSlice";

import { Link } from "react-router-dom";
import { ReactComponent as InfoIcon } from '../../../assets/images/icon-info.svg'
import { Tooltip } from "reactstrap";




const ProductCard = (props) => {
  const { id, name, image01, price, extraIngredients, description } = props.item;
  const dispatch = useDispatch();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const tooltipRef = useRef(null);

  const addToCart = () => {
    dispatch(
      cartActions.addItem({
        id,
        name,
        image01,
        price,
        extraIngredients
      })
    );
  };

  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setTooltipOpen(false);
    }
  };

  useEffect(() => {
    if (tooltipOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside); // For touch devices
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside); // For touch devices
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside); // For touch devices
    };
  }, [tooltipOpen]);

  return (
    <div className="product__item d-flex flex-column justify-content-between">
      <div className="product__content">
        {/* <img className="product__img w-50" src={image01} alt="Coco Tunisia" /> */}
        <h5>
          <Link to={`/pizzas/${id}`}>{name}</Link>
        </h5>
      </div>
      <div className="d-flex align-items-center justify-content-center mb-2" ref={tooltipRef}>
        <InfoIcon id={`product-${id}`} />
        {/* <UncontrolledTooltip
          placement="top"
          target={`product-${id}`}
          trigger="click"
        >
          {description || ''}
        </UncontrolledTooltip> */}
        <Tooltip
          placement={'top'}
          isOpen={tooltipOpen}
          target={`product-${id}`}
          toggle={toggle}
          trigger='click'


        >
          {description || ''}
        </Tooltip>
      </div>

      <div className="d-flex flex-column align-items-center justify-content-between">
        <span className="product__price mb-2">{price} TND </span>
        <button className="addTOCART__btn" onClick={addToCart}>
          Commander
        </button>
      </div>
    </div>
  );
};


export default ProductCard;

