import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "reactstrap";

import products, { getAllProduct } from "../assets/fake-data/products";
import ProductCard from "../components/UI/product-card/ProductCard";
import Helmet from "../components/Helmet/Helmet";
import ReactPaginate from "react-paginate";
import "../styles/pagination.css";
import { useLocation, useParams } from "react-router-dom";
import { restaurentInfoActions } from "../store/restaurent";
import { cartActions } from "../store/shopping-cart/cartSlice";
import { useDispatch, useSelector } from "react-redux";

const Pizzas = () => {
  let location = useLocation();
  const [pageNumber, setPageNumber] = useState(0);
  const [searchedProduct, setSearchedProduct] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const params = useParams()
  // const params = {
  //  restaurentId: 1,
  //  tableId: 4
  // }
  const dispatch = useDispatch()
  const stateRedux = useSelector((state) => state.restaurentInfo)

  // const searchedProduct = await getAllProduct()
  useEffect(async () => {
    let query = ''
    if (params.tableId) {
      query = params.tableId
    }
    if (params.restaurentId) {

      const res = await getAllProduct(params.restaurentId, query)
      dispatch(restaurentInfoActions.setRestaurentInfo(params))
      setSearchedProduct(res)
    } else if (stateRedux.restaurentId) {
      const res = await getAllProduct(stateRedux.restaurentId, query)
      setSearchedProduct(res)
    }
    else {
      setSearchedProduct([])
    }


  }, [])
  const productPerPage = 4;
  const visitedPage = pageNumber * productPerPage;
  const displayPage = searchedProduct

  const pageCount = Math.ceil(searchedProduct.length / productPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  console.log('displayPage');
  return (
    <Helmet title="Coco Tunisia">
      <Container>
        <Row>
          {displayPage?.map((item) => (
            <Col
              lg="3"
              md="4"
              sm="6"
              xs="6"
              key={item.id}
              className="mb-4 mt-4"
            >
              <ProductCard item={item} />
            </Col>
          ))}
        </Row>
      </Container>
    </Helmet>
  );
};

export default Pizzas;
