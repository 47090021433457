// all images imported from images directory
// import product_01_image_01 from "../images/product_01.jpg";
// import product_01_image_02 from "../images/product_01.1.jpg";
// import product_01_image_03 from "../images/product_01.3.jpg";

// import product_05_image_01 from "../images/product_04.jpg";
// import product_05_image_02 from "../images/product_08.jpg";
// import product_05_image_03 from "../images/product_09.jpg";

// import product_06_image_01 from "../images/bread(1).png";
// import product_06_image_02 from "../images/bread(2).png";
// import product_06_image_03 from "../images/bread(3).png";
const requestOptions = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};
export const getAllProduct = async (restaurentId, query) => {
  try {
    const response = await fetch(
      `https://backend-commandes.cocotunisia.tn/api/products/getAllProducts?restaurantId=${restaurentId}&tableId=${query}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    return data.products;
  } catch (err) {
    return err;
  }
};

export const getTableOrder = async (tableId) => {
  try {
    const response = await fetch(
      `https://backend-commandes.cocotunisia.tn/api/tables/tableOrder?tableId=${tableId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    return data.order[0];
  } catch (err) {
    return err;
  }
};

export const makeOrder = async (cartProducts, totalAmount, tableId) => {
  console.log(cartProducts, totalAmount);
  var raw = JSON.stringify({
    total: totalAmount,
    tableId: tableId,
    clientId: null,
    products: cartProducts,
    note: "",
    name: "",
  });
  try {
    const response = await fetch(
      "https://backend-commandes.cocotunisia.tn/api/tables/order",
      {
        method: "POST",
        body: raw,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return response.status;
  } catch (err) {
    return err;
  }
};

const products = getAllProduct();
// const products = [
//   // {
//   //   id: "01",
//   //   title: "Chicken Burger",
//   //   price: 24.0,
//   //   image01: product_01_image_01,
//   //   image02: product_01_image_02,
//   //   image03: product_01_image_03,
//   //   category: "Burger",

//   //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque. ",
//   // },

//   {
//     id: "02",
//     title: "Vegetarian Pizza",
//     price: 115.0,
//     image01: product_02_image_01,
//     image02: product_02_image_02,
//     image03: product_02_image_03,
//     category: "Pizza",

//     desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
//   },

//   {
//     id: "03",
//     title: "Double Cheese Margherita",
//     price: 110.0,
//     image01: product_03_image_01,
//     image02: product_03_image_02,
//     image03: product_03_image_03,
//     category: "Pizza",

//     desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
//   },

//   {
//     id: "04",
//     title: "Maxican Green Wave",
//     price: 110.0,
//     image01: product_04_image_01,
//     image02: product_04_image_02,
//     image03: product_04_image_03,
//     category: "Pizza",

//     desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
//   },

//   // {
//   //   id: "05",
//   //   title: "Cheese Burger",
//   //   price: 24.0,
//   //   image01: product_05_image_01,
//   //   image02: product_05_image_02,
//   //   image03: product_05_image_03,
//   //   category: "Burger",

//   //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
//   // },
//   // {
//   //   id: "06",
//   //   title: "Royal Cheese Burger",
//   //   price: 24.0,
//   //   image01: product_01_image_01,
//   //   image02: product_01_image_02,
//   //   image03: product_01_image_03,
//   //   category: "Burger",

//   //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
//   // },

//   {
//     id: "07",
//     title: "Seafood Pizza",
//     price: 115.0,
//     image01: product_02_image_02,
//     image02: product_02_image_01,
//     image03: product_02_image_03,
//     category: "Pizza",

//     desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
//   },

//   {
//     id: "08",
//     title: "Thin Cheese Pizza",
//     price: 110.0,
//     image01: product_03_image_02,
//     image02: product_03_image_01,
//     image03: product_03_image_03,
//     category: "Pizza",

//     desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
//   },

//   {
//     id: "09",
//     title: "Pizza With Mushroom",
//     price: 110.0,
//     image01: product_04_image_02,
//     image02: product_04_image_01,
//     image03: product_04_image_03,
//     category: "Pizza",

//     desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
//   },

//   // {
//   //   id: "10",
//   //   title: "Classic Hamburger",
//   //   price: 24.0,
//   //   image01: product_05_image_02,
//   //   image02: product_05_image_01,
//   //   image03: product_05_image_03,
//   //   category: "Burger",

//   //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
//   // },

//   // {
//   //   id: "11",
//   //   title: "Crunchy Bread ",
//   //   price: 35.0,
//   //   image01: product_06_image_01,
//   //   image02: product_06_image_02,
//   //   image03: product_06_image_03,
//   //   category: "Bread",

//   //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
//   // },

//   // {
//   //   id: "13",
//   //   title: "Loaf Bread ",
//   //   price: 35.0,
//   //   image01: product_06_image_03,
//   //   image02: product_06_image_02,
//   //   image03: product_06_image_03,
//   //   category: "Bread",

//   //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
//   // },
// ];

export default products;
